// OrderComponent.js
import React from 'react';
import './Order.scss'; // Ensure your CSS styles are in place
import axiosInstance from '../axiosConfig';

const OrderCardComponent = ({ order }) => {
  // Assuming the 'order' prop contains all necessary order details
  const statusTranslations = {
    open: 'Zu bezahlen',
    paid: 'Bezahlt',
    draft: 'Zu bezahlen', //draft status might remain if there has been a sync issue. but for user this should be fine
    deleted: 'Gelöscht',
    uncollectible: 'Abruffehler',
    void: 'Storniert'
  };

  // Define the formatDate function
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date
    const formattedDate = date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    // Format time
    const formattedTime = date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit'
    });

    // Combine date and time
    return `${formattedDate} ${formattedTime}`;
  };

  const handleOpenInvoice = () => {
    axiosInstance
      .get(`api/get-hosted-invoice-url/me/${order.id}/`)
      .then((response) => {
        const url = response.data.hostedInvoiceURL;
        if (url) {
          window.open(url, '_blank');
        } else {
          alert(
            'Kein Rechnungslink gefunden. Bitte wenden Sie sich an den Support.'
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching invoice URL:', error);
        alert(
          'Kein Rechnungslink gefunden. Bitte wenden Sie sich an den Support.'
        );
      });
  };

  return (
    <div className='card container mb-2'>
      <div className='row'>
        {/* Main column for order details */}
        <div className='col-8'>
          <div className='row'>
            <div className='col-sm-4 text-right  mt-2'>
              <strong>Status:</strong>
            </div>
            <div className='col-sm-4  mt-2'>
              {statusTranslations[order.status]}
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4 text-right'>
              <strong>Auftragsnummer:</strong>
            </div>
            <div className='col-sm-4'>{order.number}</div>
          </div>
          <div className='row'>
            <div className='col-sm-4 text-right'>
              <strong>Bestelldatum:</strong>
            </div>
            <div className='col-sm-4'>{formatDate(order.created)}</div>
          </div>
          <div className='row'>
            <div className='col-sm-4 text-right'>
              <strong>Fälligkeitsdatum:</strong>
            </div>
            <div className='col-sm-4'>{formatDate(order.due_date)}</div>
          </div>
          <div className='row'>
            <div className='col-sm-4 text-right'>
              <strong>Summe:</strong>
            </div>
            <div className='col-sm-4'>
              {order.total} {order.currency.toUpperCase()}
            </div>
          </div>
        </div>

        {/* Column for the button, aligns to the right */}
        <div className='col-4 d-flex align-items-start justify-content-end'>
          <button
            className='btn btn-primary px-5 mt-2 me'
            onClick={handleOpenInvoice}
          >
            Details
          </button>
        </div>
      </div>

      {/* Second row for additional content */}
      <div className='row'>
        {order.invoice_items.map((invoiceItem, index) => {
          const defaultImageUrl = 'path/to/your/default-image.jpg';
          const imageUrl =
            invoiceItem.product.images && invoiceItem.product.images.length > 0
              ? invoiceItem.product.images[0]
              : defaultImageUrl;

          return (
            <div
              key={invoiceItem.id || index}
              className='col-6 col-md-4 col-lg-3 col-xl-2'
            >
              <div className='card position-relative  mb-2'>
                {/* Circle Badge for Quantity */}
                <div className='circle-badge'>{invoiceItem.quantity}</div>

                <img
                  src={imageUrl}
                  className='card-img-top img-fluid img-thumbnail'
                  alt='Product'
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderCardComponent;
