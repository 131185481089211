import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './ProductList.css';
import ProductCardZoom from './ProductCardZoom';
import { useDispatch } from 'react-redux';
import { withRetryOnUnauthorized } from '../utils/withRetryOnUnauthorized';
import { addMessage } from '../redux/messageSlice'; // Adjust path as necessary
import { fetchProducts, fetchPriceByProduct } from '../utils/api';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import Drawer from '@mui/material/Drawer';
import { AiOutlineFilter } from 'react-icons/ai';

const ProductList = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to handle category selection
  const handleCategorySelect = (category) => {
    // Toggle category selection
    const updatedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((cat) => cat !== category)
      : [...selectedCategories, category];

    setSelectedCategories(updatedCategories);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Initial check for mobile view
    setIsMobileView(window.innerWidth <= 768);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter products based on selected categories
  const filteredProducts =
    products && products.length > 0
      ? products.filter((product) => {
          // If no categories selected, show all products
          if (selectedCategories.length === 0) {
            return true;
          }

          // If metadata category doesn't exist, skip filtering
          if (!product.metadata.category) {
            return false;
          }

          // Check if any selected category matches the product's category
          return selectedCategories.some((cat) =>
            product.metadata.category.includes(cat)
          );
        })
      : [];

  // Memoizing retry-enabled functions. Without useMemo withRetryOnUnauthorized would initiate a new function each time and retrigger the fetch over and over in a dependency loop
  const fetchProductsWithRetry = useMemo(
    () => withRetryOnUnauthorized(fetchProducts),
    []
  );

  const fetchPriceByProductWithRetry = useMemo(
    () => withRetryOnUnauthorized(fetchPriceByProduct),
    []
  );

  useEffect(() => {
    // Check if products exists and has items
    if (products && products.length > 0) {
      // Extract all categories from products
      const allCategories = products.reduce((acc, product) => {
        if (product.metadata && product.metadata.category) {
          const productCategories = product.metadata.category
            .split(',')
            .map((cat) => cat.trim());
          return [...acc, ...productCategories];
        }
        return acc;
      }, []);

      // Filter out unique categories
      const uniqueCategories = Array.from(new Set(allCategories));

      setCategories(uniqueCategories);
    } else {
      // Optionally set categories to an empty array if no products are available
      setCategories([]);
    }
  }, [products]);

  const resolvePrices = useCallback(
    async (productsWithoutPrice) => {
      const resolvedProducts = await Promise.all(
        productsWithoutPrice.map(async (product) => {
          const prices = await fetchPriceByProductWithRetry(product.id);
          return { ...product, prices };
        })
      );
      return resolvedProducts;
    },
    [fetchPriceByProductWithRetry]
  );

  const fetchData = useCallback(async () => {
    try {
      // Assuming fetchProductsWithRetry is defined and returns a list of products without prices
      const productsWithoutPrice = await fetchProductsWithRetry();

      // Assuming resolvePrices is a function that takes products and enriches them with prices
      // Ensure resolvePrices is either defined outside of this component or included in useEffect's dependency array
      const resolvedProducts = await resolvePrices(productsWithoutPrice);

      return resolvedProducts;
    } catch (error) {
      setProducts([]);
      dispatch(
        addMessage({
          text: 'Fehler beim Laden der Produkte, bitte versuchen Sie es später erneut.',
          messageType: 'danger',
          dismissible: true
        })
      );
      // Handle errors, maybe set an error state, or setProducts to an empty array, etc.
    }
  }, [resolvePrices, fetchProductsWithRetry, dispatch]);

  useEffect(() => {
    // Call fetchData and then update state with the result
    fetchData().then((resolvedProducts) => {
      setProducts(resolvedProducts);
    });
  }, [fetchData]); // Make sure to include any external dependencies in this array

  return (
    <Container className='productList'>
      <Col md={2}>
        <div>
          {isMobileView ? (
            <Button
              variant='light'
              onClick={() => setDrawerOpen(true)}
              style={{ width: '100%' }}
              className='mb-2'
            >
              <AiOutlineFilter /> Filter
            </Button>
          ) : (
            <>
              <h5>Kategorien</h5>
              <Form>
                {categories.map((category) => (
                  <Form.Check
                    key={category}
                    type='checkbox'
                    id={category}
                    label={category}
                    checked={selectedCategories.includes(category)}
                    onChange={() => handleCategorySelect(category)}
                  />
                ))}
              </Form>
            </>
          )}
        </div>
        {isMobileView && (
          <Drawer
            anchor='bottom'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            //PaperProps={{ style: { height: '30vh' } }}
          >
            <Container>
              {categories.map((category) => (
                <Form.Check
                  type='checkbox'
                  id={category}
                  label={category}
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCategorySelect(category)}
                  style={{
                    marginBottom: '16px',
                    marginTop: '16px',
                    caretColor: 'transparent'
                  }}
                />
              ))}
            </Container>
          </Drawer>
        )}
      </Col>
      <Col md={10}>
        {/* Conditionally render the error message */}
        {filteredProducts && filteredProducts.length > 0 ? (
          // Render ProductCardZoom components if products array is not empty
          <Row xs={1} sm={2} md={3} className='card-container'>
            {filteredProducts.map((product) => (
              <Col key={product.id} className='d-flex'>
                <ProductCardZoom
                  product={product}
                  className='productCardZoom'
                />
              </Col>
            ))}
          </Row>
        ) : (
          // Render PlaceholderComponent if products array is empty
          <p>Lade Produkte..</p>
        )}
      </Col>
    </Container>
  );
};

export default ProductList;
