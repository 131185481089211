import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import OrderCardComponent from '../components/Order';
import { useSelector } from 'react-redux';
import ProfilePageLayout from '../layouts/ProfilePageLayout';
import { Pagination } from 'react-bootstrap'; // Importing Pagination from react-bootstrap

function OrdersPage() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [openPage, setOpenPage] = useState(1);
  const [paidPage, setPaidPage] = useState(1);
  const [voidPage, setVoidPage] = useState(1);
  const pageSize = 4; // Number of items per page

  useEffect(() => {
    if (loggedIn) {
      setLoading(true);
      const cacheBuster = new Date().getTime();
      axiosInstance
        .get(`/api/invoice/me?_=${cacheBuster}`, { timeout: 10000 })
        .then(async (response) => {
          const invoices = response.data;

          const invoiceWithDetailsPromises = invoices.map(async (invoice) => {
            const response = await axiosInstance.get(
              `/api/invoice-item/me/${invoice.id}`
            );
            const invoiceItems = response.data;

            const invoiceItemsWithDetailsPromises = invoiceItems.map(
              async (invoiceItem) => {
                const response = await axiosInstance.get(
                  `/api/line-item/me/${invoiceItem.id}`
                );
                const lineItem = response.data;

                const product_response = await axiosInstance.get(
                  `/api/product/${lineItem.price.product}`
                );
                const product = product_response.data;
                return {
                  ...invoiceItem,
                  line_item: lineItem,
                  product: product
                };
              }
            );

            const invoiceItemsWithDetails = await Promise.all(
              invoiceItemsWithDetailsPromises
            );

            return { ...invoice, invoice_items: invoiceItemsWithDetails };
          });

          return Promise.all(invoiceWithDetailsPromises);
        })
        .then((invoicesWithLineItemDetails) => {
          setInvoices(invoicesWithLineItemDetails);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          console.error('Failed to fetch invoices with details:', error);
        });
    }
  }, [loggedIn]);

  const sortByDate = (invoices) => {
    return invoices.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const openInvoices = invoices.filter(
    (invoice) => invoice.status !== 'paid' && invoice.status !== 'void'
  );
  const paidInvoices = invoices.filter((invoice) => invoice.status === 'paid');
  const voidInvoices = invoices.filter((invoice) => invoice.status === 'void');

  const sortedOpenInvoices = sortByDate(openInvoices);
  const sortedPaidInvoices = sortByDate(paidInvoices);
  const sortedVoidInvoices = sortByDate(voidInvoices);

  const paginate = (invoices, page) => {
    const start = (page - 1) * pageSize;
    return invoices.slice(start, start + pageSize);
  };

  const totalPages = (invoices) => Math.ceil(invoices.length / pageSize);

  const renderPagination = (page, setPage, total) => (
    <Pagination>
      <Pagination.Prev
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      />
      {Array.from({ length: total }, (_, i) => (
        <Pagination.Item
          key={i + 1}
          active={page === i + 1}
          onClick={() => setPage(i + 1)}
        >
          {i + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => setPage(page + 1)}
        disabled={page === total}
      />
    </Pagination>
  );

  let content;
  if (!loggedIn) {
    content = (
      <div>Bitte loggen Sie sich ein, um ihre Bestellungen zu sehen.</div>
    );
  } else if (loading) {
    content = <div>Lade Bestellungen...</div>;
  } else if (error) {
    content = <div>Fehler: {error}</div>;
  } else {
    content = (
      <>
        <section>
          <h2>Offene Rechnungen</h2>
          {sortedOpenInvoices.length > 0 &&
            renderPagination(
              openPage,
              setOpenPage,
              totalPages(sortedOpenInvoices)
            )}
          {sortedOpenInvoices.length === 0 ? (
            <div>Keine offenen Rechnungen.</div>
          ) : (
            paginate(sortedOpenInvoices, openPage).map((order, index) => (
              <OrderCardComponent key={index} order={order} />
            ))
          )}
        </section>

        <section>
          <h2>Bezahlte Rechnungen</h2>
          {sortedPaidInvoices.length > 0 &&
            renderPagination(
              paidPage,
              setPaidPage,
              totalPages(sortedPaidInvoices)
            )}
          {sortedPaidInvoices.length === 0 ? (
            <div>Keine bezahlten Rechnungen.</div>
          ) : (
            paginate(sortedPaidInvoices, paidPage).map((order, index) => (
              <OrderCardComponent key={index} order={order} />
            ))
          )}
        </section>

        {sortedVoidInvoices.length > 0 && (
          <section>
            <h2>Stornierte Rechnungen</h2>
            {sortedVoidInvoices.length > 0 &&
              renderPagination(
                voidPage,
                setVoidPage,
                totalPages(sortedVoidInvoices)
              )}
            {sortedVoidInvoices.length === 0 ? (
              <div>Keine stornierten Rechnungen.</div>
            ) : (
              paginate(sortedVoidInvoices, voidPage).map((order, index) => (
                <OrderCardComponent key={index} order={order} />
              ))
            )}
          </section>
        )}
      </>
    );
  }

  return <ProfilePageLayout>{content}</ProfilePageLayout>;
}

export default OrdersPage;
